import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
// import '../src/assets/js/sb-admin-2.min.js'
import '../src/assets/css/sb-admin-2.min.css';
import vueCountryRegionSelect from 'vue-country-region-select'
import axios from 'axios'
import VueSessionStorage from 'vue-sessionstorage'
import VueRouter from 'vue-router';
import Multiselect from 'vue-multiselect'
import Toasted from 'vue-toasted'
import AOS from 'aos'
// import 'vue-material-design-icons/styles.css';

// middlewares
Vue.use(Toasted)
Vue.use(vueCountryRegionSelect)
Vue.component('multiselect', Multiselect)
Vue.use(VueSessionStorage)
Vue.prototype.$http = axios
Vue.config.productionTip = false



new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
