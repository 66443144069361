import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/afcfta_link',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../components/dashboard/Layout.vue'),
    children: [
        {
          path: '/afcfta_link/dashboard',
          name: 'dashboard-home',
          component: () => import(/* webpackChunkName: "about" */ '../components/dashboard/Home.vue'),
        },
        {
          path:'/afcfta_link/entity/:id/newproduct',
          name:'new-product',
          component:()=>import('../components/dashboard/Newproduct.vue')
        },
        {
          path:'/afcfta_link/entity/:id/all-products',
          name:'all-products',
          component:()=>import('../components/dashboard/Allproducts.vue')
        },
        {
          path:'/afcfta_link/entity/:id/my-products',
          name:'GetUserProducts',
          component:()=>import('../components/dashboard/GetUserProducts.vue')
        },
        {
          path:'/afcfta_link/entity/:id/my-product/:productID',
          name:'product-details',
          component:()=>import('../components/dashboard/products-details.vue')
        },
        {
          path:'/afcfta_link/entity/:id/my-product/:productID/edit',
          name:'edit-product',
          component:()=>import('../components/dashboard/EditProduct.vue')
        },
        {
          path:'/afcfta_link/entity/:id/certifications',
          name:'Issued-cert-list',
          component:()=>import('../components/dashboard/Issued-cert-list.vue')
        }
      ]
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 
router.beforeEach((to, from, next) => {
  if(to.name === 'dashboard') {
    next('/afcfta_link/dashboard')
  }
  next()
})
// cas authentication
router.beforeEach((to, from, next) => {
  const ticket = to.query.ticket
  if(to.path === '/' && ticket) {
    store.dispatch('validateServiceTicket',ticket)
      .then( () => next('/afcfta_link/dashboard'))
      
  }
  next()

})
export default router 
