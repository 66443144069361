import axios from 'axios'
import url from '../../url'
import Vue from 'vue'
import VueSessionStorage from 'vue-sessionstorage'


//state
export const state = {
   products:[],
   HsCodes:[],
   markets:[],
   standardRegisterationTypes:[],
   healthAndSafetyTypes:[],
   certificationTypes:[],
   myProducts:[]
}

//action
export const actions ={
    fetchHsCodes({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`${url()}/link/hs-codes`).then(response=>{
                if(response.status === 200){
                    
                    commit("SET_HSCODES", response.data)
                }
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    fetchStandardRegisterationTypes({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`${url()}/link/standard_registeration_types`).then(response=>{
                if(response.status === 200){
                    commit("SET_STANDARD_REGISTERATION_TYPES", response.data.standard_registeration_types)
                }
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    fetchHealthAndSafetyRegisterationTypes({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`${url()}/link/health_and_safety_registeration_types`).then(response=>{
                if(response.status === 200){
                    commit("SET_HEALTH_AND_SAFETY_TYPES", response.data.health_and_safety_registeration_types)
                }
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    fetchCertificationTypes({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`${url()}/link/certification_types`).then(response=>{
                if(response.status === 200){
                    commit("SET__CERTIFICATION_TYPES", response.data.certification_types)
                }
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    fetchMarket({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`${url()}/market/`).then(response=>{
                // console.log(response.data)
                if(response.status === 200){
                    commit("SET_MARKET",response.data)
                }
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
            
        })
    },
    fetchAllProducts({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`${url()}/link/products`).then(response=>{
                if(response.status === 200){
                    commit('SET_PRODUCTS',response.data)
                }
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    fetchUserProducts({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`${url()}/link/entity-products/${id}`,{
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response=>{
                commit('SET_USER_PRODUCTS',response.data.products)
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    addNewProduct({commit},data){
        return new Promise((resolve,reject)=>{
            axios.post(`${url()}/link/add-product`,data, {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
            .then(response =>{
                if(response.status === 200){
                    commit("ADD_PRODUCT",response.data.product)
                }
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })

    },
    updateProduct({commit},data){
        return new Promise((resolve,reject)=>{
            axios.post(`${url()}/link/update-product/${data.id}`,data.product, {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
            .then(response =>{
                if(response.status === 200){
                    commit("UPDATE_PRODUCT",{id:data.id,products:response.data.product})
                }
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })

    },
    addBatchNumber({commit}, data) {
       return new Promise((resolve,reject)=>{
            axios.patch(`${url()}/link/add-batch-number/${data.entityID}/${data.productID}`,data.batch_number,{
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
            .then(response =>{
                if(response.status === 200){
                    commit("ADD_BATCH_NUMBER",{id:data.productID,products:response.data.product})
                }
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    addExpiryDate({commit}, data) {
        return new Promise((resolve,reject)=>{
             axios.patch(`${url()}/link/add-expiry-date/${data.entityID}/${data.productID}`,data.batch_number,{
                 headers: {
                     'Authorization':`${Vue.prototype.$session.get('entity')}`
                 }
             })
             .then(response =>{
                 if(response.status === 200){
                     commit("ADD_BATCH_NUMBER",{id:data.productID,products:response.data.product})
                 }
                 resolve(response)
             }).catch(err=>{
                 reject(err)
             })
         })
     },
     deleteProduct({commit}, data) {
        return new Promise((resolve,reject)=>{
             axios.delete(`${url()}/link/delete-product/${data.entityID}/${data.productID}`,{
                 headers: {
                     'Authorization':`${Vue.prototype.$session.get('entity')}`
                 }
             })
             .then(response =>{
                 if(response.status === 200){
                     commit("DELETE_PRODUCT",data.key)
                 }
                 resolve(response)
             }).catch(err=>{
                 reject(err)
             })
         })
     }
}

//mutaions
export const mutations ={
    ADD_PRODUCT(state,productdata){
        state.products.push(productdata)
        state.myProducts.push(productdata)
    },
    SET_HSCODES(state,request){
        state.HsCodes= request
    },
    SET_MARKET(state,marketdata){
        state.markets = marketdata
    },
    SET_STANDARD_REGISTERATION_TYPES(state, data){
        state.standardRegisterationTypes = data
    },
    SET__CERTIFICATION_TYPES(state, data) {
        state.certificationTypes = data
    },
    SET_HEALTH_AND_SAFETY_TYPES(state, data) {
        state.healthAndSafetyTypes = data
    },
    SET_PRODUCTS(state,products){
        state.products = products
    },
    SET_USER_PRODUCTS(state,products){
        state.myProducts = products
    },
    ADD_BATCH_NUMBER(state,{id,products}) {
        state.myProducts.forEach(product => {
            if(product.id == id) {
                product.id = products
            }
        });
    },
    UPDATE_PRODUCT(state,{id,products}) {
        state.myProducts.forEach(product => {
            if(product.id == id) {
                product.id = products
            }
        });  
    },
    DELETE_PRODUCT(state,key) {
        state.myProducts.splice(key, 1)
    }
}
// Getters
export const getters = {
    myProducts:state => state.myProducts,
    products: state => state.products,
    HsCodes: state => state.HsCodes,
    markets: state => state.markets,
    standardRegisterationTypes: state => state.standardRegisterationTypes,
    certificationTypes: state => state.certificationTypes,
    healthAndSafetyTypes: state => state.healthAndSafetyTypes
    
    // 
   
}