<template>
  <nav class="navbar navbar-expand-lg  fixed-top navbar-light bg-light ">
    <div class="container">
       <a class="navbar-brand" href="#">
         <img height="70" src="../assets/rsz_logo (1).jpg" alt="">
       </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
      <i class="fa fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse " id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <a class="nav-link" href="#about">about</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" href="#psd">psd</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://afcfta.app/">App</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://afcfta.blog/">BLOG</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">Contact</a>
          </li>
          <li class="nav-item" >
          <div class="d-flex pt-2">
            <a class=" text-white" >Signup</a> <span class="px-1">/</span>
            <a class=" text-white" >Login</a>
          </div>
        </li>
      <!-- <li class="nav-item" >
        <a class="nav-link text-white" :href="`${url()}/accounts/logout?${service()}`">Logout</a>
      </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
$(window).scroll(function() {
  $("nav").toggleClass("scrolled", $(this).scrollTop() > 200);
});
export default {};
</script>

<style scoped>
.navbar-light .nav-link {
  text-transform: uppercase;
  margin-right: 20px;
  font-size: 1.2em;
  font-weight: bold;
  color: #bf2a38 !important;
   font-size: 1.2em;
}
a{
 color: #bf2a38 !important;
 text-transform: uppercase; 
 font-weight: bold;
}
span{
  color:#ba2428;
}
.navbar{
    background-color:#fff !important;
}
.bg-light.scrolled {
  background: #fff !important;
}
.fa-bars {
	color: #ba2428;
	font-size: 40px !important;
}
.navbar-toggler {
	outline: none !important;
  border:none;
}
ul li a{
  transition: all .5s;
}
ul li a:hover{
  border-bottom:2px solid  #ba2428;
}
</style>
