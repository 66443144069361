import url from '@/url'
import axios from 'axios'
import Vue from 'vue'
import Storage from '@/helpers/storage.js'

export const state = {
    currentEntity:Storage.get('currentEntity') || {},
    currentUser:Storage.get('currentUser') || []
}
const convert = require('xml-js')
export const actions = {
    getUserDetails({commit}, email) {
        return new Promise((resolve, reject) => {
            axios.get(`${url()}/account/detail/${email}`)
            .then(response => {
                // console.log(response)
                if(response) {
                    commit('SET_CURRENT_USER',response.data)
                }
                resolve(response)
            }).catch(err => reject(err))
        })
    },
    validateServiceTicket({commit, dispatch}, ticket) {
        return new Promise((resolve, reject) => {
            axios.get(`http://139.162.234.164:81/accounts/serviceValidate?service=http://localhost:8081&ticket=${ticket}`)
                .then(response => {
                    if(response) {
                        const xml = response.data
                        const data = convert.xml2json(xml, {compact: true, spaces: 4});
                        const user = JSON.parse(data)
                        const email = user["cas:serviceResponse"]["cas:authenticationSuccess"]["cas:user"]._text
                        Vue.prototype.$session.set('entity',email)
                        axios.defaults.headers.common['Authorization'] = email
                        dispatch('getUserDetails',email)
                    }
                    resolve(response)
            }).catch( err => reject(err))
        })
   },
   setCurrentEntity({commit},entity) {
       return new Promise((resolve, reject) => {
           commit('SET_CURRENT_ENTITY',entity)
           resolve()
       })
   }
}

export const mutations = {
    SET_CURRENT_ENTITY(state, entity) {
        state.setCurrentEntity = entity
        Storage.set('currentEntity', entity)
    },
    SET_CURRENT_USER(state, user) {
        state.currentUser = user
        Storage.set('currentUser',user)
    }
}
export const getters = {
    currentEntity: state => state.currentEntity,
    currentUser: state => state.currentUser
}