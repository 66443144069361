let url = () =>{
    let url
    if(process.env.NODE_ENV === 'development') {
        url = `http://139.162.234.164:81/api`
    } else {
        url = `http://139.162.234.164:81/api`
        // url = `api.fiscoins.com/api/
    }
    return url
}

export default url;